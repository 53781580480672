import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppointment } from "../../../../hooks/useAppointment";
import { useAppointmentCheckout } from "../../../../hooks/useAppointmentCheckout";
import { useAppointmentWebSetup } from "../../../../store/setup";
import {
  formatCurrency,
  maskCpf,
  maskCreditCard,
} from "../../../../utils/masks";

interface ICreditCardForm {
  cardNumber: string;
  cardOwnerName: string;
  expirationDate: string;
  securityCode: string;
  userDocument: string;
  installments: string;
  brand: string;
  cardNumberError: string;
  cardOwnerNameError: string;
  expirationDateError: string;
  securityCodeError: string;
  userDocumentError: string;
  brandError: string;
  onChangeCardNumber: (value: string) => void;
  onChangeCardOwnerName: (value: string) => void;
  onChangeExpirationDate: (value: string) => void;
  onChangeSecurityCode: (value: string) => void;
  onChangeUserDocument: (value: string) => void;
  onChangeInstallment: (value: SelectChangeEvent) => void;
  onChangeBrand: (value: SelectChangeEvent) => void;
}

interface IInstallmentOption {
  value: number;
  title: string;
}

export const CreditCardForm = ({
  cardNumber,
  cardOwnerName,
  expirationDate,
  installments,
  securityCode,
  userDocument,
  onChangeCardNumber,
  onChangeCardOwnerName,
  onChangeExpirationDate,
  onChangeSecurityCode,
  onChangeUserDocument,
  onChangeInstallment,
  cardNumberError,
  cardOwnerNameError,
  expirationDateError,
  securityCodeError,
  userDocumentError,
  brand,
  onChangeBrand,
  brandError,
}: ICreditCardForm) => {
  const {
    paymentMethod,
    appointmentType,
    serviceItem,
    serviceItemHealtPlan,
    servicePackage,
  } = useAppointment();
  const { isCombo } = useAppointmentCheckout();
  const [installmentOptions, setInstallmentOptions] = useState<
    IInstallmentOption[]
  >([
    {
      title: "1x no crédito",
      value: 1,
    },
  ]);

  const { data: setup } = useAppointmentWebSetup();

  const totalValue =
    isCombo && servicePackage
      ? Number(servicePackage.valor)
      : appointmentType === "particular" && serviceItem
      ? Number(serviceItem.valor)
      : appointmentType === "convenio" && serviceItemHealtPlan
      ? Number(serviceItemHealtPlan.valor)
      : 0;

  useEffect(() => {
    if (setup && paymentMethod) {
      const rolesPaymentMethod = setup.modules.pagamentoonline
        ? setup.modules.regrasparcelamento &&
          setup.modules.regrasparcelamento.find(
            (item) =>
              item.idmetodopagamento === paymentMethod?.idmetodopagamento
          )
        : undefined;
      if (rolesPaymentMethod) {
        const roles = rolesPaymentMethod.regrasparcelamento;
        if (roles) {
          const installmentOptionsToAdd: IInstallmentOption[] = [];
          roles.forEach((role) => {
            if (role.valorminimo > 0 && totalValue >= role.valorminimo) {
              installmentOptionsToAdd.push({
                title: `${role.parcela}x de ${formatCurrency(
                  totalValue / role.parcela
                )} `,
                value: role.parcela,
              });
            }
          });

          setInstallmentOptions(installmentOptionsToAdd);
        }
      } else {
        setInstallmentOptions([
          {
            title: `1x de ${formatCurrency(totalValue)} `,
            value: 1,
          },
        ]);
      }
    }
  }, [totalValue, setup, paymentMethod]);

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          fullWidth
          id="cardNumber"
          name="cardNumber"
          label="Número do cartão"
          size="small"
          placeholder="0000 0000 0000 0000"
          autoFocus
          value={maskCreditCard(cardNumber)}
          type="tel"
          onChange={(e) => onChangeCardNumber(e.target.value)}
          error={cardNumberError !== " "}
          helperText={cardNumberError}
          margin="none"
        />
      </Grid>
      {paymentMethod && paymentMethod.credito && (
        <Grid item lg={3} md={6} xs={12}>
          <FormControl fullWidth margin="none" size="small">
            <InputLabel id="installments">Parcelas</InputLabel>
            <Select
              labelId="installments"
              id="installments"
              value={installments}
              label="Parcelas"
              size="small"
              onChange={onChangeInstallment}
            >
              {installmentOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error> </FormHelperText>
          </FormControl>
        </Grid>
      )}
      {paymentMethod && (
        <Grid item lg={paymentMethod.credito ? 3 : 6 } md={6} xs={12}>
          <FormControl fullWidth margin="none" size="small">
            <InputLabel id="brand-label">Bandeira</InputLabel>
            <Select
              labelId="brand-label"
              id="brand"
              value={brand}
              label="Bandeira"
              size="small"
              onChange={onChangeBrand}
            >
              {paymentMethod.bandeira?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={brandError !== " "}>
              {brandError}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item lg={12} md={12} xs={12}>
        <TextField
          id="holderName"
          name="holderName"
          label="Nome do titular do cartão"
          size="small"
          placeholder="Como está escrito no cartão"
          fullWidth
          value={cardOwnerName}
          type="text"
          onChange={(e) => onChangeCardOwnerName(e.target.value)}
          error={cardOwnerNameError !== " "}
          helperText={cardOwnerNameError}
          margin="none"
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          id="date"
          name="date"
          label="Data de validade"
          size="small"
          fullWidth
          placeholder="MM/AA"
          value={expirationDate}
          type="tel"
          onChange={(e) => onChangeExpirationDate(e.target.value)}
          error={expirationDateError !== " "}
          helperText={expirationDateError}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          id="cvv"
          name="cvv"
          label="CVV"
          size="small"
          fullWidth
          placeholder="000"
          value={securityCode}
          type="tel"
          onChange={(e) => onChangeSecurityCode(e.target.value)}
          error={securityCodeError !== " "}
          helperText={securityCodeError}
        />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <TextField
          id="cpf"
          name="cpf"
          label="CPF do titular"
          placeholder="000.000.000-00"
          size="small"
          fullWidth
          value={maskCpf(userDocument)}
          type="tel"
          onChange={(e) => onChangeUserDocument(e.target.value)}
          error={userDocumentError !== " "}
          helperText={userDocumentError}
        />
      </Grid>
    </Grid>
  );
};
