import {
  Avatar,
  Card,
  CardContent,
  Collapse,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

import DebitIcon from "../../../assets/card.png";
import CreditIcon from "../../../assets/debit.png";
import PixIcon from "../../../assets/pix.png";
import { useAppointment } from "../../../hooks/useAppointment";
import { usePaymentMethods } from "../../../store/paymentMethods";
import { IPaymentMethod } from "../../../types";
import { CreditCardForm } from "./CreditCardForm";

export const paymentMethodIcons = [
  {
    name: "credito",
    icon: CreditIcon,
  },
  {
    name: "debito",
    icon: DebitIcon,
  },
  {
    name: "cartao",
    icon: CreditIcon,
  },
  {
    name: "transferencia",
    icon: PixIcon,
  },
  {
    name: "pix",
    icon: PixIcon,
  },
];

interface ICreditCardForm {
  cardNumber: string;
  cardOwnerName: string;
  expirationDate: string;
  securityCode: string;
  userDocument: string;
  installments: string;
  brand: string;
  cardNumberError: string;
  cardOwnerNameError: string;
  expirationDateError: string;
  securityCodeError: string;
  userDocumentError: string;
  brandError: string;
  onChangeCardNumber: (value: string) => void;
  onChangeCardOwnerName: (value: string) => void;
  onChangeExpirationDate: (value: string) => void;
  onChangeSecurityCode: (value: string) => void;
  onChangeUserDocument: (value: string) => void;
  onChangeInstallment: (value: SelectChangeEvent) => void;
  onChangeBrand: (value: SelectChangeEvent) => void;
}

const CheckoutPaymentMethod = ({
  cardNumber,
  cardOwnerName,
  expirationDate,
  installments,
  securityCode,
  userDocument,
  onChangeCardNumber,
  onChangeCardOwnerName,
  onChangeExpirationDate,
  onChangeSecurityCode,
  onChangeUserDocument,
  onChangeInstallment,
  cardNumberError,
  cardOwnerNameError,
  expirationDateError,
  securityCodeError,
  userDocumentError,
  brand,
  brandError,
  onChangeBrand,
}: ICreditCardForm) => {
  const {
    paymentMethod,
    handleChangeAppointmentPaymentMethod,
    handleChangeFinalize,
    clinic,
  } = useAppointment();
  const [newPaymentMethods, setNewPaymentMethods] = useState<IPaymentMethod[]>(
    []
  );

  const { data: paymentMethods } = usePaymentMethods({
    clinicId: clinic?.idclinica
  });

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (paymentMethods) {
      const payments: any[] = [];
      paymentMethods.forEach((payment) => {
        if (
          payment.formapagamento === "cartao" ||
          payment.formapagamento === "credito"
        ) {
          if (payment.credito) {
            payments.push({
              ...payment,
              nome: "Cartão de crédito",
              debito: false,
            });
          }
          if (payment.debito) {
            payments.push({
              ...payment,
              nome: "Cartão de débito",
              credito: false,
            });
          }
        }

        if (
          payment.formapagamento === "pix" ||
          payment.formapagamento === "transferencia"
        ) {
          payments.push({ ...payment, nome: "Pix" });
        }
      });

      setNewPaymentMethods(payments);
    }
  }, [paymentMethods]);

  useEffect(() => {
    handleChangeFinalize(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container sx={{ py: 3, px: 1 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <List>
                  <Grid container spacing={2}>
                    {paymentMethod ? (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card variant="outlined">
                          <ListItemButton
                            selected={true}
                            sx={{
                              borderWidth: 1,
                            }}
                          >
                            <ListItemIcon>
                              <Radio
                                edge="start"
                                checked={true}
                                disableRipple
                                tabIndex={-1}
                              />
                            </ListItemIcon>
                            {!matchesSM && (
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    bgcolor: "transparent",
                                  }}
                                >
                                  <img
                                    src={
                                      paymentMethodIcons.find((item) =>
                                        paymentMethod.debito
                                          ? item.name === "debito"
                                          : item.name ===
                                            paymentMethod.formapagamento
                                      )?.icon
                                    }
                                    alt="Método de pagamento"
                                    width={24}
                                    height={24}
                                  />
                                </Avatar>
                              </ListItemAvatar>
                            )}
                            <ListItemText primary={paymentMethod.nome} />

                            <ListItemSecondaryAction
                              onClick={() =>
                                handleChangeAppointmentPaymentMethod(null)
                              }
                            >
                              Editar
                            </ListItemSecondaryAction>
                          </ListItemButton>

                          <Collapse
                            in={paymentMethod.formapagamento === "cartao"}
                          >
                            <CardContent>
                              <CreditCardForm
                                cardNumber={cardNumber}
                                cardOwnerName={cardOwnerName}
                                expirationDate={expirationDate}
                                installments={installments}
                                securityCode={securityCode}
                                userDocument={userDocument}
                                onChangeCardNumber={onChangeCardNumber}
                                onChangeCardOwnerName={onChangeCardOwnerName}
                                onChangeExpirationDate={onChangeExpirationDate}
                                onChangeSecurityCode={onChangeSecurityCode}
                                onChangeUserDocument={onChangeUserDocument}
                                onChangeInstallment={onChangeInstallment}
                                cardNumberError={cardNumberError}
                                cardOwnerNameError={cardOwnerNameError}
                                expirationDateError={expirationDateError}
                                securityCodeError={securityCodeError}
                                userDocumentError={userDocumentError}
                                brand={brand}
                                brandError={brandError}
                                onChangeBrand={onChangeBrand}
                              />
                            </CardContent>
                          </Collapse>
                        </Card>
                      </Grid>
                    ) : newPaymentMethods ? (
                      newPaymentMethods.map(
                        (payment) =>
                          !paymentMethod && (
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              key={
                                payment.idmetodopagamento +
                                payment.debito +
                                payment.credito
                              }
                            >
                              <Card variant="outlined">
                                <ListItemButton
                                  onClick={() =>
                                    handleChangeAppointmentPaymentMethod(
                                      payment
                                    )
                                  }
                                  sx={{
                                    borderWidth: 1,
                                  }}
                                >
                                  <ListItemIcon>
                                    <Radio
                                      edge="start"
                                      checked={false}
                                      disableRipple
                                      tabIndex={-1}
                                    />
                                  </ListItemIcon>
                                  <ListItemAvatar>
                                    <Avatar
                                      sx={{
                                        bgcolor: "transparent",
                                      }}
                                    >
                                      <img
                                        src={
                                          paymentMethodIcons.find((item) =>
                                            payment.debito
                                              ? item.name === "debito"
                                              : item.name ===
                                                payment.formapagamento
                                          )?.icon
                                        }
                                        alt="teste"
                                        width={24}
                                        height={24}
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText primary={payment.nome} />
                                </ListItemButton>
                              </Card>
                            </Grid>
                          )
                      )
                    ) : (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card variant="outlined">
                          <ListItemButton
                            disabled
                            sx={{
                              borderWidth: 1,
                            }}
                          >
                            <ListItemText primary="Nenhum método de pagamento encontrado" />
                          </ListItemButton>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckoutPaymentMethod;
