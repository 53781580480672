import React, { createContext, useState } from "react";

interface IAppointmentCheckoutContext {
  isCombo: boolean;
  isPayment: boolean;
  handleChangeIsCombo: (value: boolean) => void;
  handleChangeIsPayment: (value: boolean) => void; 
}

export const AppointmentCheckoutContext =
  createContext<IAppointmentCheckoutContext>({} as IAppointmentCheckoutContext);

type IAppointmentCheckoutProvider = {
  children: React.ReactNode;
};

export const AppointmentCheckoutProvider: React.FC<
  IAppointmentCheckoutProvider
> = ({ children }) => {
  const [isPayment, setIsPayment] = useState(false);
  const [isCombo, setIsCombo] = useState(false);
  
  const handleChangeIsCombo = (value: boolean) => {
    setIsCombo(value);
  };
  const handleChangeIsPayment = (value: boolean) => {
    setIsPayment(value);
  };


  return (
    <AppointmentCheckoutContext.Provider
      value={{
        isPayment,
        handleChangeIsPayment,
        isCombo,
        handleChangeIsCombo
      }}
    >
      {children}
    </AppointmentCheckoutContext.Provider>
  );
};
