import { QueryKey } from "react-query";

export const createUseAppointmentKey = ({
  patientId,
  clinicId,
}: {
  patientId: string;
  clinicId?: string;
}): QueryKey => ["useAppointment", patientId, clinicId];

export const createUseAppointmentByResponsibleIdKey = ({
  patientId,
}: {
  patientId: string;
}): QueryKey => ["useAppointmentByResponsibleId", patientId];

export const createUseAppointmentByIdKey = ({
  appointmentId,
}: {
  appointmentId: string;
}): QueryKey => ["useAppointmentById", appointmentId];

export const createUseServiceOrderIdKey = ({
  serviceOrderId,
}: {
  serviceOrderId: string;
}): QueryKey => ["useServiceOrderIdKey", serviceOrderId];
