import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  debounce,
  Grid,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CalendarBlank, CaretDown, X } from "phosphor-react";
import { useState } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { HealthPlanModal } from "../../components/HealthPlanModal";
import { useAppointment } from "../../hooks/useAppointment";
import { useClinic } from "../../store/clinic";
import { useCategoryHealthPlanItems } from "../../store/healthPlan";
import { useServiceGroups } from "../../store/serviceGroup";
import { useServiceItem } from "../../store/serviceItem";
import { useAppointmentWebSetup } from "../../store/setup";
import { HomeNavbar } from "./components/HomeNavbar";

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  fontWeight: "bold",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

export const GroupItems = styled("ul")({
  padding: 0,
});

export default function Home() {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    clinic,
    serviceGroup,
    serviceItem,
    handleChangeAppointmentServiceGroup,
    handleChangeAppointmentClinic,
    handleChangeAppointmentServiceItem,
    handleChangeServiceItemHealtPlan,
    handleChangeHasRedirectedByHome,
    handleChangeAppointmentType,
    handleChangeHealthPlan,
    handleChangeCategoryHealthPlan,
    appointmentType,
    healthPlan,
    categoryHealthPlan,
    serviceItemHealtPlan,
  } = useAppointment();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpenAppointmentTypeModal, setIsOpenAppointmentTypeModal] =
    useState(false);

  const { data: setup } = useAppointmentWebSetup();

  const goToAppointment = () => {
    if (!serviceGroup) {
      return navigate(`/agendamento`);
    }

    if (setup && setup.modules.combo) {
      if (serviceGroup.nome.toLowerCase() === "combos") {
        return navigate(`/agendamento-combo`);
      }
    }

    if (serviceGroup.nome.toLowerCase() === "consultas" && !appointmentType) {
      handleChangeHasRedirectedByHome(true);
    }

    return navigate(`/agendamento`);
  };

  const { data: serviceGroups, isLoading: isLoadingServiceGroups } =
    useServiceGroups();

  const { data: clinics, isLoading: isLoadingClinics } = useClinic();

  const { data: serviceItems, isLoading: isLoadingServiceItems } =
    useServiceItem(
      {
        clinicId: clinic ? clinic.idclinica : "",
        searchTerm: searchTerm,
        serviceGroupId: serviceGroup ? serviceGroup.idgruposervico : "",
      },
      {
        enabled: appointmentType === "particular" && !!serviceGroup && !!clinic,
      }
    );

  const {
    data: serviceItemsHealthPlan,
    isLoading: isLoadingServiceItemsHealthPlan,
  } = useCategoryHealthPlanItems(
    {
      healthPlanCategoryId: categoryHealthPlan ? categoryHealthPlan.id : "",
      clinicId: clinic ? clinic.idclinica : "",
      searchTerm: searchTerm,
      serviceGroupId: serviceGroup ? serviceGroup.idgruposervico : "",
    },
    {
      enabled:
        appointmentType === "convenio" &&
        !!serviceGroup &&
        !!clinic &&
        !!categoryHealthPlan &&
        !!healthPlan,
    }
  );

  const options = serviceItems ? serviceItems : [];

  const optionsHealthPlan = serviceItemsHealthPlan
    ? serviceItemsHealthPlan
    : [];

  const onChangeSearchTermDebounced = debounce((query: string) => {
    setSearchTerm(query);
  }, 300);

  return (
    <>
      <HomeNavbar dark={false} />
      <Box
        minHeight={matchesSM ? "50vh" : "80vh"}
        position="relative"
        sx={{
          mb: 2,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: matchesSM ? "calc(100vh - 200px)" : "calc(90vh - 64px)",
            display: "flex",
            alignItems: matchesSM ? "center" : "start",
            justifyContent: "center",
            flexDirection: "column",
            pt: 5,
          }}
        >

          <Grid container spacing={4} zIndex={1} mb={6}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Grid container display="flex" justifyContent="end">
                <Grid item lg={12} md={10} sm={12} xs={12}>
                  <Grid container zIndex={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Card
                        sx={{
                          // backgroundColor: matchesSM
                          //   ? theme.palette.background.default
                          //   : "rgba(255, 255, 255, 0.6)",
                          backgroundColor: "#0149ac", //color customer
                          backdropFilter: "blur(16px)",
                          border: "solid",
                          borderColor: "#0149ac",
                        }}
                      >
                        <CardContent
                          sx={{
                            px: 4,
                            py: 4,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item lg={12}>
                              <Typography
                                component="h1"
                                variant="h3"
                                sx={{
                                  color: "white",
                                  fontSize: 24,
                                  fontWeight: "bold",
                                  mb: 2,
                                }}
                              >
                                {setup &&
                                setup.configs.home.text_appointment_card
                                  ? setup.configs.home.text_appointment_card
                                  : `Agende uma consulta ou exame agora`}
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                              <Typography
                                component="h1"
                                variant="h6"
                                fontWeight={600}
                                sx={{
                                  fontSize: 16,
                                  mb: 1,
                                  color: "white"
                                }}
                              >
                                Selecione um serviço
                              </Typography>
                              <Autocomplete
                                id="service-group"
                                options={serviceGroups ? serviceGroups : []}
                                getOptionLabel={(value) =>
                                  value.nome.toUpperCase()
                                }
                                loading={isLoadingServiceGroups}
                                onChange={(event, newValue) => {
                                  if (
                                    newValue &&
                                    newValue.nome.toLowerCase() !== "combos"
                                  ) {
                                    if (setup && setup.modules.convenio) {
                                      setIsOpenAppointmentTypeModal(true);
                                    }
                                  }
                                  handleChangeAppointmentServiceGroup(newValue);
                                  handleChangeAppointmentClinic(null);
                                  handleChangeAppointmentServiceItem(null);
                                  handleChangeAppointmentType("particular");
                                  handleChangeHealthPlan(null);
                                  handleChangeCategoryHealthPlan(null);

                                  ReactGA.event({
                                    category: "agendamento-web",
                                    action: "start",
                                    label: "categoria", //1
                                  });
                                }}
                                value={serviceGroup || null}
                                popupIcon={
                                  <CaretDown
                                    size={18}
                                    color="white"
                                    weight="fill"
                                  />
                                }
                                clearIcon={
                                  <X size={18} color="white" weight="fill" />
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Selecione um serviço"
                                    variant="outlined"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        color: "black",
                                        backgroundColor: "white",
                                        "&.Mui-disabled": {
                                          backgroundColor:
                                            "rgba(131, 131, 131, 0.1)",
                                          color: "#353535 !important",
                                          borderColor: "transparent",
                                        },
                                        "&.Mui-disabled fieldset": {
                                          borderColor: "transparent",
                                        },
                                      },
                                    }}
                                    size="medium"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                              <Typography
                                component="h1"
                                variant="h6"
                                fontWeight={600}
                                sx={{
                                  color: "white", // color seconday customer
                                  fontSize: 16,
                                  mb: 1,

                                }}
                              >
                                Selecione uma clínica
                              </Typography>
                              <Autocomplete
                                id="clinic"
                                options={clinics ? clinics : []}
                                getOptionLabel={(value) =>
                                  value.nome.toUpperCase()
                                }
                                loading={isLoadingClinics}
                                disabled={!serviceGroup}
                                onChange={(event, newValue) => {
                                  handleChangeAppointmentClinic(newValue);

                                  ReactGA.event({
                                    category: "agendamento-web",
                                    action: "start",
                                    label: "clinica", //2
                                  });
                                }}
                                value={clinic || null}
                                popupIcon={
                                  <CaretDown
                                    size={18}
                                    color="white"
                                    weight="fill"
                                  />
                                }
                                clearIcon={
                                  <X size={18} color="white" weight="fill" />
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Selecione uma clínica"
                                    variant="outlined"
                                    size="medium"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        color: "black",
                                        backgroundColor: "white",
                                        "&.Mui-disabled": {
                                          backgroundColor: "white", //color secondary customer
                                          color: "#353535 !important",
                                          borderColor: "transparent",
                                        },
                                        "&.Mui-disabled fieldset": {
                                          borderColor: "transparent",
                                        },
                                      },
                                    }}
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            {appointmentType === "particular" && (
                              <>
                                {!serviceGroup ? (
                                  <Grid item lg={12} md={12} xs={12}>
                                    <Typography
                                      component="h1"
                                      variant="h6"
                                      sx={{
                                        color: "white",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        mb: 1,
                                      }}
                                    >
                                      Selecione uma especialidade
                                    </Typography>
                                    <Autocomplete
                                      id="service-item"
                                      //   options={serviceItems ? serviceItems : []}
                                      options={options.sort(
                                        (a, b) =>
                                          -b.categoriaservico.nome.localeCompare(
                                            a.categoriaservico.nome
                                          )
                                      )}
                                      getOptionLabel={(value) => value.nome}
                                      groupBy={(option) =>
                                        option.categoriaservico.nome
                                      }
                                      loading={isLoadingServiceItems}
                                      disabled={true}
                                      onChange={(event, newValue) => {}}
                                      popupIcon={
                                        <CaretDown
                                          size={18}
                                          color="white"
                                          weight="fill"
                                        />
                                      }
                                      clearIcon={
                                        <X
                                          size={18}
                                          color="white"
                                          weight="fill"
                                        />
                                      }
                                      value={serviceItem || null}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Selecione uma especialidade"
                                          variant="outlined"
                                          size="medium"
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              color: "black",
                                              backgroundColor: "white",
                                              "&.Mui-disabled": {
                                                backgroundColor: "#fff",
                                                color: "#353535 !important",
                                                borderColor: "transparent",
                                              },
                                              "&.Mui-disabled fieldset": {
                                                borderColor: "transparent",
                                              },
                                            },
                                          }}
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </Grid>
                                ) : (
                                  serviceGroup &&
                                  serviceGroup.nome.toLowerCase() !==
                                    "combos" && (
                                    <Grid item lg={12} md={12} xs={12}>
                                      <Typography
                                        component="h1"
                                        variant="h6"
                                        sx={{
                                          color: "white", //color seconday customer
                                          fontWeight: 600,
                                          mb: 1,
                                        }}
                                      >
                                        {serviceGroup.nome.toLowerCase() ===
                                        "exames"
                                          ? "Selecione um exame"
                                          : "Selecione uma especialidade"}
                                      </Typography>
                                      <Autocomplete
                                        id="service-item"
                                        options={
                                          serviceGroup.nome.toLowerCase() !==
                                          "consultas"
                                            ? options.sort(
                                                (a, b) =>
                                                  -b.categoriaservico.nome.localeCompare(
                                                    a.categoriaservico.nome
                                                  )
                                              )
                                            : options
                                        }
                                        getOptionLabel={(value) => value.nome}
                                        groupBy={
                                          serviceGroup.nome.toLowerCase() !==
                                          "consultas"
                                            ? (option) =>
                                                option.categoriaservico.nome
                                            : undefined
                                        }
                                        loading={isLoadingServiceItems}
                                        onInputChange={(e, value) =>
                                          onChangeSearchTermDebounced(value)
                                        }
                                        filterOptions={(options) => options}
                                        blurOnSelect
                                        isOptionEqualToValue={(option, value) =>
                                          option.iditemservico ===
                                          value.iditemservico
                                        }
                                        noOptionsText="Nesta unidade não há disponibilidade, verifique em outra unidade ou ligue para a central"
                                        disabled={!clinic || !serviceGroup}
                                        onChange={(event, newValue) => {
                                          handleChangeAppointmentServiceItem(
                                            newValue
                                          );

                                          ReactGA.event({
                                            category: "agendamento-web",
                                            action: "start",
                                            label: "categoria-servico", //3
                                          });
                                        }}
                                        renderGroup={(params) => (
                                          <li key={params.key}>
                                            <GroupHeader>
                                              {params.group}
                                            </GroupHeader>
                                            <GroupItems>
                                              {params.children}
                                            </GroupItems>
                                          </li>
                                        )}
                                        popupIcon={
                                          <CaretDown
                                            size={18}
                                            color="white"
                                            weight="fill"
                                          />
                                        }
                                        clearIcon={
                                          <X
                                            size={18}
                                            color="white"
                                            weight="fill"
                                          />
                                        }
                                        value={serviceItem || null}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder={
                                              serviceGroup.nome.toLowerCase() ===
                                              "exames"
                                                ? "Selecione um exame"
                                                : "Selecione uma especialidade"
                                            }
                                            variant="outlined"
                                            size="medium"
                                            sx={{
                                              "& .MuiOutlinedInput-root": {
                                                color: "black",
                                                backgroundColor:"white", // color secodary customer
                                                "&.MuiButtonBase-root": {
                                                  color: "#FFF !important",
                                                },
                                                "&.Mui-disabled": {
                                                  backgroundColor:
                                                    "rgba(131, 131, 131, 0.1)",
                                                  color: "#353535 !important",
                                                  borderColor: "transparent",
                                                },
                                                "&.Mui-disabled fieldset": {
                                                  borderColor: "transparent",
                                                },
                                              },
                                            }}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </Grid>
                                  )
                                )}
                              </>
                            )}
                            {setup &&
                              setup.modules.convenio &&
                              appointmentType === "convenio" && (
                                <>
                                  {!serviceGroup ? (
                                    <Grid item lg={12} md={12} xs={12}>
                                      <Typography
                                        component="h1"
                                        variant="h6"
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.text.disabled,
                                          fontSize: 16,
                                          fontWeight: 600,
                                          mb: 1,
                                        }}
                                      >
                                        Selecione uma especialidade
                                      </Typography>
                                      <Autocomplete
                                        id="service-item-helth-plan"
                                        //   options={serviceItems ? serviceItems : []}
                                        options={optionsHealthPlan.sort(
                                          (a, b) =>
                                            -b.itemservico.categoriaservico.nome.localeCompare(
                                              a.itemservico.categoriaservico
                                                .nome
                                            )
                                        )}
                                        getOptionLabel={(value) =>
                                          value.itemservico.nome
                                        }
                                        groupBy={(option) =>
                                          option.itemservico.categoriaservico
                                            .nome
                                        }
                                        loading={
                                          isLoadingServiceItemsHealthPlan
                                        }
                                        disabled={true}
                                        onChange={(event, newValue) => {}}
                                        popupIcon={
                                          <CaretDown
                                            size={18}
                                            color="white"
                                            weight="fill"
                                          />
                                        }
                                        clearIcon={
                                          <X
                                            size={18}
                                            color="white"
                                            weight="fill"
                                          />
                                        }
                                        value={serviceItemHealtPlan || null}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="Selecione uma especialidade"
                                            variant="outlined"
                                            size="medium"
                                            sx={{
                                              "& .MuiOutlinedInput-root": {
                                                color: "#FFF",
                                                backgroundColor:
                                                  theme.palette.primary.main,
                                                "&.MuiButtonBase-root": {
                                                  color: "#FFF !important",
                                                },
                                                "&.Mui-disabled": {
                                                  backgroundColor:
                                                    "rgba(131, 131, 131, 0.1)",
                                                  color: "#353535 !important",
                                                  borderColor: "transparent",
                                                },
                                                "&.Mui-disabled fieldset": {
                                                  borderColor: "transparent",
                                                },
                                              },
                                            }}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </Grid>
                                  ) : (
                                    serviceGroup &&
                                    serviceGroup.nome.toLowerCase() !==
                                      "combos" && (
                                      <Grid item lg={12} md={12} xs={12}>
                                        <Typography
                                          component="h1"
                                          variant="h6"
                                          sx={{
                                            color: (theme) =>
                                              !clinic || !serviceGroup
                                                ? theme.palette.text.disabled
                                                : theme.palette.text.primary,
                                            fontSize: 16,
                                            fontWeight: 600,
                                            mb: 1,
                                          }}
                                        >
                                          Selecione uma especialidade
                                        </Typography>
                                        <Autocomplete
                                          id="service-item"
                                          options={
                                            serviceGroup.nome.toLowerCase() !==
                                            "consultas"
                                              ? optionsHealthPlan.sort(
                                                  (a, b) =>
                                                    -b.itemservico.categoriaservico.nome.localeCompare(
                                                      a.itemservico
                                                        .categoriaservico.nome
                                                    )
                                                )
                                              : optionsHealthPlan
                                          }
                                          getOptionLabel={(value) =>
                                            value.itemservico.nome
                                          }
                                          groupBy={
                                            serviceGroup.nome.toLowerCase() !==
                                            "consultas"
                                              ? (option) =>
                                                  option.itemservico
                                                    .categoriaservico.nome
                                              : undefined
                                          }
                                          loading={
                                            isLoadingServiceItemsHealthPlan
                                          }
                                          disabled={!clinic || !serviceGroup}
                                          onInputChange={(e, value) =>
                                            onChangeSearchTermDebounced(value)
                                          }
                                          filterOptions={(options) => options}
                                          blurOnSelect
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) =>
                                            option.iditemservico ===
                                            value.iditemservico
                                          }
                                          noOptionsText="Nesta unidade não há disponibilidade, verifique em outra unidade ou ligue para a central"
                                          onChange={(event, newValue) => {
                                            handleChangeServiceItemHealtPlan(
                                              newValue
                                            );
                                            handleChangeAppointmentServiceItem(
                                              null
                                            );

                                            ReactGA.event({
                                              category: "agendamento-web",
                                              action: "start",
                                              label: "categoria-servico", //3
                                            });
                                          }}
                                          renderGroup={(params) => (
                                            <li key={params.key}>
                                              <GroupHeader>
                                                {params.group}
                                              </GroupHeader>
                                              <GroupItems>
                                                {params.children}
                                              </GroupItems>
                                            </li>
                                          )}
                                          popupIcon={
                                            <CaretDown
                                              size={18}
                                              color="white"
                                              weight="fill"
                                            />
                                          }
                                          clearIcon={
                                            <X
                                              size={18}
                                              color="white"
                                              weight="fill"
                                            />
                                          }
                                          value={serviceItemHealtPlan || null}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder={
                                                serviceGroup.nome.toLowerCase() ===
                                                "exames"
                                                  ? "Selecione um exame"
                                                  : "Selecione uma especialidade"
                                              }
                                              variant="outlined"
                                              size="medium"
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                  color: "#FFF",
                                                  backgroundColor:
                                                    theme.palette.primary.main,
                                                  "&.MuiButtonBase-root": {
                                                    color: "#FFF !important",
                                                  },
                                                  "&.Mui-disabled": {
                                                    backgroundColor:
                                                      "rgba(131, 131, 131, 0.1)",
                                                    color: "#353535 !important",
                                                    borderColor: "transparent",
                                                  },
                                                  "&.Mui-disabled fieldset": {
                                                    borderColor: "transparent",
                                                  },
                                                },
                                              }}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </Grid>
                                    )
                                  )}
                                </>
                              )}
                            <Grid item lg={12} md={12} xs={12}>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                }}
                              >
                                <Grid item lg={12} md={12} xs={12}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => goToAppointment()}
                                    startIcon={<CalendarBlank />}
                                    size="large"
                                    color="secondary"
                                  >
                                    {setup &&
                                    setup.configs.home.text_appointment_button
                                      ? setup.configs.home
                                          .text_appointment_button
                                      : `Agendar agora`}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12} style={{marginLeft: "60px"}}>
              {setup && setup.configs.home.title ? (
                <Typography
                  component="h1"
                  variant="h3"
                  dangerouslySetInnerHTML={{
                    __html: setup.configs.home.title,
                  }}
                  sx={{
                    color: (theme) =>
                      matchesSM
                        ? theme.palette.onPrimary.main
                        : theme.palette.onPrimaryContainer.main,
                    fontSize: 44,
                    mb: 2,
                  }}
                />
              ) : (
                <Typography
                  component="h1"
                  variant="h3"
                  sx={{
                    color: (theme) =>
                      matchesSM
                        ? theme.palette.onPrimary.main
                        : theme.palette.onPrimaryContainer.main,
                    fontSize: 44,
                    mb: 2,
                  }}
                >
                  <b>Profissional</b> de <b>saúde</b> por um preço <b>justo!</b>
                </Typography>
              )}
              {setup && setup.configs.home.subtitle ? (
                <Typography
                  component="h1"
                  variant="h3"
                  sx={{
                    color: (theme) =>
                      matchesSM
                        ? theme.palette.onPrimary.main
                        : theme.palette.secondary.main,
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  {setup.configs.home.subtitle}
                </Typography>
              ) : (
                <Typography
                  component="h1"
                  variant="h3"
                  sx={{
                    color: (theme) =>
                      matchesSM
                        ? theme.palette.onPrimary.main
                        : theme.palette.secondary.main,
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  Agendamento online 24h
                </Typography>
              )}
              <Typography
                  component="h1"
                  variant="h3"
                  sx={{
                    color: "#0149ac",
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  <a style={{
                    color: "#0149ac",
                    fontWeight: "700",
                    backgroundColor: "white"
                  }} 
                  target="_blank"
                  href="https://www.instagram.com/medmaispara/">
                    @medmaispara
                  </a>
                </Typography>
                <Typography
                  component="h1"
                  variant="h3"
                  sx={{
                    color: (theme) =>
                      matchesSM
                        ? theme.palette.onPrimary.main
                        : theme.palette.onPrimaryContainer.main,
                    fontSize: 18,
                    mb: 2,
                  }}
                >
                  <a style={{
                      color: "#0149ac",
                      fontWeight: "700",
                      backgroundColor: "white"
                    }} 
                    target="_blank"
                    href="https://www.medmaispara.com.br/">
                      medmaispara.com.br
                  </a>
                </Typography>
            </Grid>
          </Grid>
        </Container>

        <Box
          height={matchesSM ? "100%" : "100%"}
          width={matchesXL ? "55vw" : matchesSM ? "100%" : "60vw"}
          position="absolute"
          right="120"
          top="0"
          // marginLeft="-20px"
          sx={{
            background: matchesSM
              ? setup && setup.configs.home.url_background_image
                ? `rgba(0, 0, 0, 0.5) url(${setup.configs.home.url_background_image})`
                : `rgba(0, 0, 0, 0.5) url(./background.png)`
              : setup && setup.configs.home.url_background_image
              ? `url(${setup.configs.home.url_background_image})`
              : `url(./background.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundPositionX: "center",
            // backgroundPositionY: "top",
            overflow: "hidden",
            borderBottomLeftRadius: matchesSM ? 25 : 0,
            borderBottomRightRadius: matchesSM ? 0 : 25,
            backgroundBlendMode: "darken",
          }}
        />
      </Box>

      {setup && setup.modules.convenio && (
        <HealthPlanModal
          open={isOpenAppointmentTypeModal}
          onClose={() => {
            handleChangeAppointmentServiceGroup(null);
            handleChangeAppointmentClinic(null);
            handleChangeAppointmentServiceItem(null);
            setIsOpenAppointmentTypeModal(false);
            handleChangeAppointmentType("particular");
            handleChangeHealthPlan(null);
            handleChangeCategoryHealthPlan(null);
            handleChangeHasRedirectedByHome(false);
          }}
          onConfirm={() => {
            handleChangeHasRedirectedByHome(false);
            setIsOpenAppointmentTypeModal(false);
          }}
        />
      )}

      <Footer />
    </>
  );
}
