import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { CheckCircle } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import {
  IPayServiceOrderResponse
} from "../../../types";

interface ICheckoutOrderCreatedPending {
  order: IPayServiceOrderResponse;
}

const CheckoutOrderCreatedProcessing = ({
  order,
}: ICheckoutOrderCreatedPending) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleConfirm = async () => {
    return navigate("/paciente/dashboard")
  };
  
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        display="flex"
        pb={5}
      >
        {order && (
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Grid
                    item
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    mb={2}
                    mt={2}
                  >
                    <CheckCircle size={44} color={theme.palette.warning.main} />

                    <Typography
                      fontSize={22}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                      }}
                    >
                      Pagamento em processamento
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} xs={12} textAlign="center">
                    <Typography
                      fontSize={14}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                      }}
                    >
                      O seu agendamento foi realizado e
                      o pagamento está sendo processado. Aguarde!
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    lg={8}
                    md={8}
                    xs={12}
                    mt={2}
                    mb={2}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      width="100%"
                      mt={1}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        onClick={() => {
                          handleConfirm();
                        }}
                      >
                        Ver meus agendamentos
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CheckoutOrderCreatedProcessing;
