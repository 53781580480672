import { UseQueryOptions, useQuery } from "react-query";
import { api } from "../../services/api";
import { IPaymentMethod } from "../../types";
import { createUsePaymentMethodsKey } from "./keys";

export const usePaymentMethods = (
  { clinicId }: { clinicId?: string },
  options?: UseQueryOptions<IPaymentMethod[]>
) => {
  return useQuery(
    createUsePaymentMethodsKey(clinicId),
    () =>
      api
        .get(
          `/users-patients/payment-methods${
            clinicId ? `?idclinica=${clinicId}` : ``
          }`
        )
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
    }
  );
};
