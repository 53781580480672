import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppointment } from "../../../../../hooks/useAppointment";
import { formatCurrency, formatName } from "../../../../../utils/masks";
import { useAppointmentCheckout } from "../../../../../hooks/useAppointmentCheckout";

export const CheckoutSidebar = () => {
  const {
    serviceItem,
    serviceItemHealtPlan,
    appointmentType,
    clinic,
    servicePackage,
  } = useAppointment();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  const { isCombo } = useAppointmentCheckout();
  return (
    <>
      {matchesSM && (
        <Grid container mt={4}  sx={{ py: 3, px: 1 }}>
          <Grid item lg={12} xs={12}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    alignItems="center"
                    display="flex"
                    textAlign="center"
                    flexDirection="column"
                  >
                    {isCombo && servicePackage ? (
                      <>
                        <Typography
                          fontSize={18}
                          mt={1}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                            fontWeight: 500,
                          }}
                        >
                          {formatName(servicePackage.titulo)}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        fontSize={18}
                        mt={1}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                          fontWeight: 500,
                        }}
                      >
                        {appointmentType === "particular" && serviceItem
                          ? formatName(serviceItem.nome)
                          : appointmentType === "convenio" &&
                            serviceItemHealtPlan &&
                            formatName(serviceItemHealtPlan.itemservico.nome)}
                      </Typography>
                    )}
                    {clinic && (
                      <Typography
                        fontSize={14}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                          mt: 1,
                          mb: 2,
                        }}
                      >
                        Clínica: {clinic.nome}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Stack
                      justifyContent="space-between"
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        fontSize={16}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                        }}
                      >
                        Total
                      </Typography>
                      {appointmentType === "particular" && serviceItem && (
                        <Typography
                          fontSize={18}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                              fontWeight: 700,
                          }}
                        >
                          {formatCurrency(Number(serviceItem.valor))}
                        </Typography>
                      )}
                      {appointmentType === "convenio" &&
                        serviceItemHealtPlan && (
                          <Typography
                            fontSize={18}
                            sx={{
                              color: (theme) =>
                                theme.palette.onPrimaryContainer.main,
                                fontWeight: 700,
                            }}
                          >
                            {formatCurrency(Number(serviceItemHealtPlan.valor))}
                          </Typography>
                        )}
                      {isCombo && servicePackage && (
                        <Typography
                          fontSize={18}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                              fontWeight: 700,
                          }}
                        >
                          {formatCurrency(Number(servicePackage.valor))}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {!matchesSM && (
        <Box
          sx={{
            flexShrink: { md: 0 },
            width: 320,
            backgroundColor: "rgba(221,227,234, 0.4)",
            ml: 2,
            py: 3,
            px: 5,
          }}
        >
          <div
            style={{
              position: "sticky",
              top: 24,
            }}
          >
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    fontSize={14}
                    sx={{
                      color: (theme) => theme.palette.onPrimaryContainer.main,
                      fontWeight: 500,
                    }}
                  >
                    Detalhes do seu agendamento
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={6}>
              <Grid item lg={12} xs={12}>
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    alignItems="center"
                    display="flex"
                    textAlign="center"
                    flexDirection="column"
                  >
                    {isCombo && servicePackage ? (
                      <>
                        <Typography
                          fontSize={18}
                          mt={1}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                            fontWeight: 500,
                          }}
                        >
                          {formatName(servicePackage.titulo)}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        fontSize={18}
                        mt={1}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                          fontWeight: 500,
                        }}
                      >
                        {appointmentType === "particular" && serviceItem
                          ? formatName(serviceItem.nome)
                          : appointmentType === "convenio" &&
                            serviceItemHealtPlan &&
                            formatName(serviceItemHealtPlan.itemservico.nome)}
                      </Typography>
                    )}
                    {clinic && (
                      <Typography
                        fontSize={14}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                          mt: 1,
                          mb: 2,
                        }}
                      >
                        Clínica: {clinic.nome}
                      </Typography>
                    )}
                    {isCombo && servicePackage && (
                      <Chip size="small" label="Combo" color="primary" />
                    )}
                    {appointmentType === "convenio" && serviceItemHealtPlan && (
                      <Chip size="small" label="Convênio" color="secondary" />
                    )}
                    {appointmentType === "particular" && serviceItem && (
                      <Chip size="small" label="Particular" color="info" />
                    )}
                  </Grid>
                  <Grid item lg={12} xs={12} mt={2}>
                    <Divider />
                    <Stack
                      mt={2}
                      justifyContent="space-between"
                      direction="row"
                    >
                      <Typography
                        fontSize={14}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                        }}
                      >
                        Total
                      </Typography>
                      {appointmentType === "particular" && serviceItem && (
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                          }}
                        >
                          {formatCurrency(Number(serviceItem.valor))}
                        </Typography>
                      )}
                      {appointmentType === "convenio" &&
                        serviceItemHealtPlan && (
                          <Typography
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.onPrimaryContainer.main,
                            }}
                          >
                            {formatCurrency(Number(serviceItemHealtPlan.valor))}
                          </Typography>
                        )}
                      {isCombo && servicePackage && (
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                          }}
                        >
                          {formatCurrency(Number(servicePackage.valor))}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Box>
      )}
    </>
  );
};
