import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { IScheduleByProfessionalResponse } from "../../types";
import {
  createUseAvailabilityMapKey,
  createUseAvailabilityTimeKey,
} from "./keys";

export const useAvailabilityTime = (
  {
    serviceItemId,
    clinicId,
    date,
    healthPlanId,
    patientId,
    servicePackageId,
  }: {
    date: string;
    clinicId: string;
    serviceItemId: string[];
    healthPlanId?: string;
    patientId?: string;
    servicePackageId?: string;
  },
  options?: UseQueryOptions<IScheduleByProfessionalResponse[]>
) => {
  return useQuery(
    createUseAvailabilityTimeKey({
      clinicId,
      date,
      serviceItemId,
      healthPlanId,
      patientId,
      servicePackageId,
    }),
    () =>
      api
        .get(
          `/users-patients/available-times?iditemservico=${serviceItemId}&idclinica=${clinicId}&data=${date}${
            healthPlanId ? `&idconvenio=${healthPlanId}` : ""
          }${servicePackageId ? `&idcombo=${servicePackageId}` : ""}${
            patientId ? `&idpaciente=${patientId}` : ""
          }`
        )
        .then((res) => res.data.data.scheduleByProfessional),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAvailabilityMap = (
  {
    length = 30,
    serviceItemId,
    clinicId,
    date,
    healthPlanId,
    patientId,
    servicePackageId,
  }: {
    date: string;
    clinicId: string;
    serviceItemId: string[];
    length: number;
    healthPlanId?: string;
    patientId?: string;
    servicePackageId?: string;
  },
  options?: UseQueryOptions<Date[]>
) => {
  return useQuery(
    createUseAvailabilityMapKey({
      clinicId,
      date,
      length,
      serviceItemId,
      healthPlanId,
      patientId,
      servicePackageId,
    }),
    () =>
      api
        .get(
          `/users-patients/availability-map?length=${length}&iditemservico=${serviceItemId}&idclinica=${clinicId}&data=${date}${
            healthPlanId ? `&idconvenio=${healthPlanId}` : ""
          }${servicePackageId ? `&idcombo=${servicePackageId}` : ""}${
            patientId ? `&idpaciente=${patientId}` : ""
          }`
        )
        .then((res) => {
          const dates: Date[] = [];
          res.data.data.forEach((item: any) => {
            dates.push(new Date(item.date));
          });
          return dates;
        }),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false,
    }
  );
};
