export const validateCpf = (cpf: string) => {
  let Soma;
  let Resto;
  Soma = 0;
  if (cpf === "00000000000") {
    return false;
  }
  for (let i = 1; i <= 9; i++) {
    Soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  if (Resto !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }
  Soma = 0;
  for (let i = 1; i <= 10; i++) {
    Soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }
  Resto = (Soma * 10) % 11;
  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }
  if (Resto !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }
  return true;
};

export function validarCPF(cpf: string): boolean {
  // Remover caracteres não numéricos do CPF
  cpf = cpf.replace(/\D/g, '');

  // Verificar se o CPF possui 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verificar se todos os dígitos são iguais, o que é inválido
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  // Validar os dígitos verificadores
  const cpfArray: number[] = cpf.split('').map(Number);

  // Função auxiliar para cálculo do dígito verificador
  const calcularDigitoVerificador = (digitos: number[]): number => {
    let soma = 0;
    let peso = digitos.length + 1;

    for (let i = 0; i < digitos.length; i++) {
      soma += digitos[i] * peso;
      peso--;
    }

    const resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
  };

  // Verificar o primeiro dígito verificador
  const digitoVerificador1 = calcularDigitoVerificador(cpfArray.slice(0, 9));
  if (cpfArray[9] !== digitoVerificador1) {
    return false;
  }

  // Verificar o segundo dígito verificador
  const digitoVerificador2 = calcularDigitoVerificador(cpfArray.slice(0, 10));
  if (cpfArray[10] !== digitoVerificador2) {
    return false;
  }

  // CPF válido
  return true;
}


export const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const creditCards = [
  {
    name: "Visa",
    length: "13,16",
    prefixes: "4",
    checkdigit: true,
  },
  {
    name: "MasterCard",
    length: "16",
    prefixes: "51,52,53,54,55",
    checkdigit: true,
  },
  {
    name: "DinersClub",
    length: "14,16",
    prefixes: "36,38,54,55",
    checkdigit: true,
  },
  {
    name: "CarteBlanche",
    length: "14",
    prefixes: "300,301,302,303,304,305",
    checkdigit: true,
  },
  {
    name: "AmEx",
    length: "15",
    prefixes: "34,37",
    checkdigit: true,
  },
  {
    name: "Discover",
    length: "16",
    prefixes: "6011,622,64,65",
    checkdigit: true,
  },
  { name: "JCB", length: "16", prefixes: "35", checkdigit: true },
  {
    name: "enRoute",
    length: "15",
    prefixes: "2014,2149",
    checkdigit: true,
  },
  {
    name: "Solo",
    length: "16,18,19",
    prefixes: "6334,6767",
    checkdigit: true,
  },
  {
    name: "Switch",
    length: "16,18,19",
    prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
    checkdigit: true,
  },
  {
    name: "Maestro",
    length: "12,13,14,15,16,18,19",
    prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
    checkdigit: true,
  },
  {
    name: "VisaElectron",
    length: "16",
    prefixes: "4026,417500,4508,4844,4913,4917",
    checkdigit: true,
  },
  {
    name: "LaserCard",
    length: "16,17,18,19",
    prefixes: "6304,6706,6771,6709",
    checkdigit: true,
  },
];

export const formatExpirationDate = (value: string) => {
  return value
    .replace(/[^0-9]/g, "") // To allow only numbers
    .replace(/^([2-9])$/, "0$1") // To handle 3 > 03
    .replace(/^(1{1})([3-9]{1})$/, "0$1/$2") // To handle 13 > 01/3
    .replace(/^0{1,}/g, "0") // To handle 00 > 0
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/, "$1/$2"); // To handle 113 > 11/3
};

export function singularizeWithArticle(str: string, art?: boolean): string {
  const exceptions: Record<string, string> = {
    pés: "pé",
    mãos: "mão",
    olhos: "olho",
    ouvidos: "ouvido",
    dentes: "dente",
    anos: "ano",
  };

  const lastWord = str.split(" ").pop() ?? str;
  const singular = exceptions[lastWord] ?? lastWord.slice(0, -1); // remove o 's' do final, exceto para as exceções
  const article = getArticle(singular, art);

  return `${article} ${singular}`;
}

export function singularize(str: string): string {
  const exceptions: Record<string, string> = {
    pés: "pé",
    mãos: "mão",
    olhos: "olho",
    ouvidos: "ouvido",
    dentes: "dente",
    anos: "ano",
  };

  const lastWord = str.split(" ").pop() ?? str;
  const singular = exceptions[lastWord] ?? lastWord.slice(0, -1); // remove o 's' do final, exceto para as exceções

  return `${singular}`;
}

function getArticle(word: string, art?: boolean) {
  const lastLetter = word.slice(-1);

  if (lastLetter === "o" || lastLetter === "u" || lastLetter === "e") {
    return art ? "um" : "o seu";
  } else if (lastLetter === "a" || lastLetter === "i") {
    return art ? "uma" : "a sua";
  } else if (word.slice(-2) === "ão") {
    return art ? "um" : "o seu";
  } else if (word.slice(-2) === "ês") {
    return art ? "um" : "o seu";
  } else if (word.slice(-2) === "ns") {
    return art ? "um" : "o seu";
  } else {
    return art ? "uma" : "a sua";
  }
}

export function scrollBy(
  element: any,
  value: any,
  duration: any,
  easingFunc: any
) {
  var startTime: any;
  var startPos = element.scrollTop;
  var clientHeight = element.clientHeight;
  var maxScroll = element.scrollHeight - clientHeight;
  var scrollIntendedDestination = startPos + value;
  // low and high bounds for possible scroll destinations
  var scrollEndValue = Math.min(
    Math.max(scrollIntendedDestination, 0),
    maxScroll
  );
  // create recursive function to call every frame
  var scroll = function (timestamp: any) {
    startTime = startTime || timestamp;
    var elapsed = timestamp - startTime;
    element.scrollTop =
      startPos + (scrollEndValue - startPos) * easingFunc(elapsed / duration);
    elapsed <= duration && window.requestAnimationFrame(scroll);
  };
  // call recursive function
  if (startPos !== scrollEndValue) window.requestAnimationFrame(scroll);
}

export function greeting(): string {
  const currentDate: Date = new Date();
  const currentHour: number = currentDate.getHours();

  if (currentHour >= 6 && currentHour < 12) {
    return "Bom dia";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Boa tarde";
  } else {
    return "Boa noite";
  }
}
