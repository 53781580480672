import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0149ac",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#EE6167",
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: "#4E5F7D",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FEFEFF",
      paper: "#FEFEFF",
    },
    success: {
      main: "#55DAD2",
      // main: "#A1E3CB",
    },
    error: {
      main: "#BA1A1A",
      light: "#FF4F4F",
    },
    text: {
      primary: "#002022",
      disabled: "#8B9198",
    },
    onPrimary: {
      main: "#FFFFFF",
    },
    onPrimaryContainer: {
      main: "#002022",
    },
    onSecondary: {
      main: "#FFFFFF",
    },
    onSecondaryContainer: {
      main: "#051F21",
    },
    onSurface: {
      main: "#191C1C",
    },
    onSurfaceVariant: {
      main: "#3F4849",
    },
    onTertiary: {
      main: "#FFFFFF",
    },
    onTertiaryContainer: {
      main: "#091B36",
    },
    primaryContainer: {
      main: "#6FF6FF",
    },
    secondaryContainer: {
      main: "#CCE8E9",
    },
    surfaceVariant: {
      main: "#DAE4E5",
    },
    tertiaryContainer: {
      main: "#D6E3FF",
    },
    neutral: {
      main: "#DDE3EA",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h1: {
      color: "#002022",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        sizeLarge: {
          height: 56,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 10px 24px -24px rgba(33, 35, 38, 0.5)",
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
    onPrimary: PaletteOptions["primary"];
    onPrimaryContainer: PaletteOptions["primary"];
    primaryContainer: PaletteOptions["primary"];
    onSecondary: PaletteOptions["primary"];
    onSecondaryContainer: PaletteOptions["primary"];
    secondaryContainer: PaletteOptions["primary"];
    onTertiary: PaletteOptions["primary"];
    onTertiaryContainer: PaletteOptions["primary"];
    tertiaryContainer: PaletteOptions["primary"];
    onSurface: PaletteOptions["primary"];
    onSurfaceVariant: PaletteOptions["primary"];
    surfaceVariant: PaletteOptions["primary"];
    neutral: PaletteOptions["primary"];
  }

  interface Palette {
    tertiary: Palette["primary"];
    onPrimary: Palette["primary"];
    onPrimaryContainer: Palette["primary"];
    primaryContainer: Palette["primary"];
    onSecondary: Palette["primary"];
    onSecondaryContainer: Palette["primary"];
    secondaryContainer: Palette["primary"];
    onTertiary: Palette["primary"];
    onTertiaryContainer: Palette["primary"];
    tertiaryContainer: Palette["primary"];
    onSurface: Palette["primary"];
    onSurfaceVariant: Palette["primary"];
    surfaceVariant: Palette["primary"];
    neutral: Palette["primary"];
  }
}
