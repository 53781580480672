import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { CheckCircle } from "phosphor-react";
import {  useNavigate } from "react-router-dom";
import {
  APPOINTMENT_STATUS,
  AppointmentType,
  IPayServiceOrderResponse,
  IServiceItem,
} from "../../../types";
import { useAuthenticatedUser } from "../../../store/user";
import { useAppointment } from "../../../hooks/useAppointment";
import { useAppointments } from "../../../store/appointment";

interface ICheckoutOrderCreatedPending {
  order: IPayServiceOrderResponse;
}

const CheckoutOrderCreatedApproved = ({
  order,
}: ICheckoutOrderCreatedPending) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: user } = useAuthenticatedUser();

  const {
    clinic,
    account,
    dependent,
    servicePackage,
  } = useAppointment();

  const id =
    account === 0 && user
      ? user.paciente.idpaciente
      : dependent
      ? dependent.idpaciente
      : "";

  const { data: appointments } = useAppointments(
    {
      patientId: id,
      clinicId: clinic ? clinic.idclinica : undefined,
    },
    {
      enabled: !!(account === 0
        ? user?.paciente.idpaciente
        : dependent?.idpaciente),
    }
  );

  const comboItemsAppointmented: IServiceItem[] = [];
  const comboAppointmented: AppointmentType[] = [];
  const comboNotAppointmented: AppointmentType[] = [];

  if (appointments && servicePackage) {
    appointments.forEach((appointment) => {
      appointment.orderitems.forEach((item) => {
        if (item.idcombo && item.idcombo === servicePackage?.idcombo) {
          if (
            !(
              appointment.status === APPOINTMENT_STATUS.CANCELLED ||
              appointment.status === APPOINTMENT_STATUS.MISSED
            )
          ) {
            comboItemsAppointmented.push(item.itemservicos);
            comboAppointmented.push(appointment);
          } else {
            comboNotAppointmented.push(appointment);
          }
        }
      });
    });
  }

  const promotionalItemsServiceAppointmented =
    servicePackage?.itenspromocionais.filter((itemPromotional) => {
      return comboItemsAppointmented.find(
        (item) => item.iditemservico === itemPromotional.iditemservico
      );
    });

  const lastItemServiceAppointmented =
    promotionalItemsServiceAppointmented &&
    promotionalItemsServiceAppointmented.length > 0
      ? promotionalItemsServiceAppointmented[
          promotionalItemsServiceAppointmented.length - 1
        ]
      : null;

  const itemsEnabledToAppointment = servicePackage
    ? servicePackage.itenspromocionais.filter(
        (item) => item.itemservico.agendamento
      )
    : [];

  const lastItemToAppointmentIndex =
    lastItemServiceAppointmented && itemsEnabledToAppointment
      ? itemsEnabledToAppointment.findIndex(
          (item) =>
            item.iditempromocional ===
            lastItemServiceAppointmented?.iditempromocional
        )
      : -1;

  const nextItemToAppointment =
    servicePackage &&
    servicePackage.itenspromocionais.length > lastItemToAppointmentIndex &&
    itemsEnabledToAppointment.length > 0
      ? comboNotAppointmented.length === itemsEnabledToAppointment.length
        ? itemsEnabledToAppointment[lastItemToAppointmentIndex + 1]
        : comboNotAppointmented.length === 0
        ? itemsEnabledToAppointment[lastItemToAppointmentIndex + 1]
        : null
      : null;

  const handleConfirm = async () => {
    if (nextItemToAppointment && nextItemToAppointment.agendamentoativo) {
      const settled =
        comboAppointmented.length > 0 &&
        comboAppointmented.find((a) => a.status === APPOINTMENT_STATUS.SETTLED)
          ? true
          : false;
      const missed =
        comboNotAppointmented.length > 0 &&
        comboNotAppointmented.find(
          (a) => a.status === APPOINTMENT_STATUS.MISSED
        )
          ? true
          : false;

      if (settled && missed) {
        handleViewSummary(settled && missed);
      } else {
        navigate(`/agendamento-combo/item-servico`, { replace: true });
      }
    } else {
      const settled =
        comboAppointmented.length > 0 &&
        comboAppointmented.find((a) => a.status === APPOINTMENT_STATUS.SETTLED)
          ? true
          : false;
      const missed =
        comboNotAppointmented.length > 0 &&
        comboNotAppointmented.find(
          (a) => a.status === APPOINTMENT_STATUS.MISSED
        )
          ? true
          : false;

      handleViewSummary(settled && missed);
    }
  };

  const handleViewSummary = async (settled?: boolean) => {
    navigate(
      `/agendamento-combo/item-servico?summary=true${
        settled ? "&settled=true" : ""
      }`
    );
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        display="flex"
        pb={5}
      >
        {order && (
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Grid
                    item
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    mb={2}
                    mt={2}
                  >
                    <CheckCircle size={44} color={theme.palette.success.main} />

                    <Typography
                      fontSize={22}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                      }}
                    >
                      Pagamento aprovado
                    </Typography>
                  </Grid>
                  <Grid item lg={8} md={8} xs={12} textAlign="center">
                    <Typography
                      fontSize={14}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                      }}
                    >
                      O pagamento do Combo ({servicePackage?.titulo}) foi aprovado
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    lg={8}
                    md={8}
                    xs={12}
                    mt={2}
                    mb={2}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      width="100%"
                      mt={1}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        onClick={() => {
                          handleConfirm();
                        }}
                      >
                        AGENDAR ITENS DO COMBO
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CheckoutOrderCreatedApproved;
