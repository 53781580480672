import { Grid } from "@mui/material";
import { addDays, addMonths } from "date-fns";
import { useEffect, useState } from "react";
import { DateContainer } from "../../../../../../components/DateContainer";
import { useAppointment } from "../../../../../../hooks/useAppointment";
import { ScheduleCalendar } from "../ScheduleCalendar";

interface IScheduleDateList {
  availabilityMap?: Date[];
  isLoadingAvailabilityMap: boolean;
}

export const ScheduleDateList = ({
  availabilityMap,
  isLoadingAvailabilityMap,
}: IScheduleDateList) => {
  const { selectedDate, handleChangeSelectedDate } = useAppointment();

  const [activeSlot, setActiveSlot] = useState("0");
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  function disableWeekends(date: Date) {
    if (availabilityMap) {
      const dateInterdites = availabilityMap.map((arrVal) => {
        return arrVal.getTime();
      });

      return !dateInterdites.includes(date.getTime());
    }
    return false;
  }

  useEffect(() => {
    if (availabilityMap) {
      handleChangeSelectedDate(availabilityMap[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityMap]);

  return (
    <Grid container columnSpacing={2}>
      {availabilityMap &&
      !isLoadingAvailabilityMap &&
      availabilityMap.length > 0 ? (
        <>
          <Grid item lg={4} md={4} xs={4}>
            <DateContainer
              isActive={activeSlot === "0"}
              onClick={() => {
                handleChangeSelectedDate(availabilityMap[0]);
                setActiveSlot("0");
                setShowCalendar(false);
              }}
              date={new Date(availabilityMap[0])}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={4}>
            <DateContainer
              isActive={activeSlot === "1"}
              onClick={() => {
                handleChangeSelectedDate(
                  availabilityMap.length > 1
                    ? availabilityMap[1]
                    : addDays(new Date(), 1)
                );
                setActiveSlot("1");
                setShowCalendar(false);
              }}
              disabled={availabilityMap.length > 1 ? false : true}
              date={availabilityMap.length > 1 ? new Date(availabilityMap[1]) :  addDays(new Date(), 1)}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={4}>
            {availabilityMap.length > 1 ? (
              <DateContainer
                isActive={
                  activeSlot === "2" &&
                  (selectedDate !== availabilityMap[0] ||
                    selectedDate !== availabilityMap[1])
                }
                date={selectedDate}
                onClick={() => {
                  setShowCalendar(true);
                }}
                isCalendar
              />
            ) : (
              <DateContainer
                isActive={
                  activeSlot === "2" && selectedDate !== availabilityMap[0]
                }
                date={selectedDate}
                onClick={() => {
                  setShowCalendar(true);
                }}
                isCalendar
              />
            )}
            <ScheduleCalendar
              isOpen={showCalendar}
              onChange={(newValue) => {
                handleChangeSelectedDate(
                  newValue ? newValue : availabilityMap[0]
                );
                setShowCalendar(false);
                setActiveSlot("2");
              }}
              maxDate={addMonths(
                availabilityMap[availabilityMap.length - 1],
                1
              )}
              minDate={availabilityMap[0]}
              disableDates={disableWeekends}
              onClose={() => {
                setShowCalendar(false);
              }}
              value={new Date(selectedDate)}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item lg={4} md={4} xs={4}>
            <DateContainer
              disabled={true}
              isActive={false}
              onClick={() => {}}
              date={
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate() + 1
                )
              }
            />
          </Grid>
          <Grid item lg={4} md={4} xs={4}>
            <DateContainer
              disabled={true}
              isActive={false}
              onClick={() => {
                setActiveSlot("1");
              }}
              date={
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate() + 2
                )
              }
            />
          </Grid>
          <Grid item lg={4} md={4} xs={4}>
            <DateContainer
              disabled={true}
              isActive={false}
              date={
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate() + 1
                )
              }
              onClick={() => {}}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
