import {
  Box,
  Container,
  Grid,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppointmentAdsCard from "../../components/AppointmentAdsCard";
import { Footer } from "../../components/Footer";
import { useAppointment } from "../../hooks/useAppointment";
import { useAuthenticatedUser } from "../../store/user";
import { HomeNavbar } from "../Home/components/HomeNavbar";
import { ListServicePackage } from "./components/ListServicePackages";
import { ServicePackageAccount } from "./components/ServicePackageAccount";
import CheckoutLayout from "../Checkout/CheckoutLayout";

export default function AppointmentServicePackage() {
  const theme = useTheme();
  const { clinic, serviceGroup, appointmentStep, servicePackage } =
    useAppointment();
  const { data: user } = useAuthenticatedUser();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!serviceGroup) {
      navigate("/");
    }
  }, [serviceGroup, navigate]);

  return (
    <Box
      minHeight="100vh"
      id="scroll-container"
      position="relative"
      sx={{
        pb: 2,
        backgroundColor: '#0149ac',
      }}
    >
      <HomeNavbar dark={true} />
      <LinearProgress color="secondary" variant="determinate" value={12.5} />
      <Box
        minHeight="100vh"
        position="relative"
        sx={{
          pb: 4,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: matchesSM ? "calc(100vh - 200px)" : "calc(90vh - 64px)",
          }}
        >
          <Grid container mt={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AppointmentAdsCard
                title="Encontre o combo que melhor atenda às suas necessidades e
              preferências"
              />
            </Grid>
          </Grid>

          <ListServicePackage />

          {user &&
            serviceGroup &&
            clinic &&
            servicePackage &&
            appointmentStep > 0 && <ServicePackageAccount />}
        </Container>
      </Box>
      <Footer />

      <CheckoutLayout  />
    </Box>
  );
}
