import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import {
  CheckCircle,
  CheckSquare,
  Clock,
  Copy,
  DeviceMobileCamera,
} from "phosphor-react";
import { useEffect, useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QrCodeIcon from "../../../assets/QrCode.svg";
import { api } from "../../../services/api";
import { useApplicationId } from "../../../store/user";
import { IPayServiceOrderResponse } from "../../../types";
import {
  formatCurrency,
  getReturnValuesCounter,
  padTo2Digits,
} from "../../../utils/masks";

interface IParams extends Params {
  slug: string;
}

interface ICheckoutOrderCreatedPending {
  order: IPayServiceOrderResponse;
}

const CheckoutOrderCreatedPending = ({
  order,
}: ICheckoutOrderCreatedPending) => {
  const theme = useTheme();
  const { slug } = useParams<IParams>();
  const navigate = useNavigate();
  const { data: applicationId } = useApplicationId();

  const [counter, setCounter] = useState(0);
  const [copyClipboard, setCopyClipboard] = useState(false);
  const [started, setStarted] = useState(false);
  const [days, hours, minutes, seconds] = getReturnValuesCounter(counter);
  useEffect(() => {
    if (order && started) {
      if (order.expires_at) {
        const countDownDate = new Date(order.expires_at).getTime();
        const interval = setInterval(() => {
          setCounter(countDownDate - new Date().getTime());
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [started, order]);

  useEffect(() => {
    if (order && days + hours + minutes + seconds <= 0 && started) {
      setStarted(false);
      closeServiceOrder(
        order.service_order_id,
        order.order_id,
        applicationId?.idusuario
      ).then(() => {
        navigate(`/agendamento`);
      });
    }
  }, [
    days,
    hours,
    minutes,
    seconds,
    order,
    started,
    navigate,
    slug,
    applicationId,
  ]);

  const onCopyCode = (text?: string) => {
    setCopyClipboard(true);
    navigator.clipboard.writeText(text || "");

    setTimeout(() => {
      setCopyClipboard(false);
    }, 2000);
  };

  const closeServiceOrder = async (
    service_order_id?: string,
    order_id?: string,
    user_id?: string
  ) => {
    try {
      await api.put(`/users-patients/service-orders/${service_order_id}/close`, {
        order_id,
        user_id,
      });
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      } else {
        toast.error("Não foi possível fechar a ordem de serviço");
      }
    }
  };

  useEffect(() => {
    if (order && order.expires_at) {
      if (new Date(order.expires_at) < new Date()) {
        closeServiceOrder(
          order.service_order_id,
          order.order_id,
          applicationId?.idusuario
        ).then(() => {
          navigate(`/agendamento`, {
            replace: true,
          });
        });
      } else {
        setStarted(true);
        setCounter(new Date(order.expires_at).getTime() - new Date().getTime());
      }
    }
  }, [order, navigate, applicationId]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        display="flex"
        pb={5}
      >
        {order && (
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Grid
                    item
                    lg={8}
                    md={8}
                    sm={12}
                    xs={12}
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    mb={2}
                    mt={2}
                  >
                    <CheckCircle size={44} color={theme.palette.success.main} />

                    <Typography
                      fontSize={22}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                      }}
                    >
                      {order.status === "pendente"
                        ? `Agendamento aguardando pagamento`
                        : "Agendamento pendente"}
                    </Typography>
                  </Grid>
                  {order.expires_at && (
                    <Grid item lg={8} md={8} xs={12} textAlign="center">
                      <Typography
                        fontSize={14}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                        }}
                      >
                        O pix no valor {formatCurrency(order.amount)} foi gerado
                        e pode ser pago até o dia{" "}
                        {format(
                          new Date(order.expires_at),
                          "dd/MM/yyyy 'às' HH:mm"
                        )}
                      </Typography>
                    </Grid>
                  )}
                  {started && (
                    <Grid item lg={8} xs={8} mt={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        width="100%"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Clock size={22} />
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                            fontWeight: 500,
                            width: 44,
                          }}
                        >
                          {`${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}

                  <Grid
                    item
                    lg={8}
                    md={8}
                    xs={12}
                    mt={2}
                    mb={2}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box
                      sx={{
                        height: 225,
                        width: 225,
                        backgroundColor: theme.palette.onPrimary.main,
                      }}
                    >
                      <img
                        src={order.qr_code_url}
                        alt="Pix payment"
                        width="100%"
                      />
                    </Box>
                    
                    <Stack
                      direction="row"
                      justifyContent="center"
                      width="100%"
                      mt={1}
                    >
                      <Button
                        variant={copyClipboard ? "contained" : "text"}
                        size="small"
                        color={copyClipboard ? "primary" : "inherit"}
                        disableElevation
                        onClick={() => onCopyCode(order.qr_code)}
                        startIcon={copyClipboard ?<CheckCircle size={18}  /> :<Copy size={18}  />}
                      >
                        Copiar código
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid item lg={8} md={8} xs={12} mt={1}>
                    <Grid container>
                      <Grid item lg={12} md={12} xs={12} textAlign="center">
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                            fontWeight: 600,
                          }}
                        >
                          Como pagar?
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xs={12}>
                        <Stack
                          spacing={2}
                          direction="row"
                          alignItems="center"
                          width="100%"
                        >
                          <DeviceMobileCamera size={18} color="#73777F" />
                          <Typography
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.onPrimaryContainer.main,
                            }}
                          >
                            Abra o app do seu banco e escolha pagar com Pix
                          </Typography>
                        </Stack>
                        <Stack
                          spacing={2}
                          direction="row"
                          alignItems="center"
                          width="100%"
                        >
                          <img src={QrCodeIcon} alt="qr-code" width={18} />
                          <Typography
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.onPrimaryContainer.main,
                            }}
                          >
                            Selecione a opção pagar com QR Code e escaneie o
                            código acima ou copie o código e selecione a opção
                            Pix Copia e Cola
                          </Typography>
                        </Stack>
                        <Stack
                          spacing={2}
                          direction="row"
                          alignItems="center"
                          width="100%"
                          mt={2}
                        >
                          <CheckSquare size={18} color="#73777F" />
                          <Typography
                            fontSize={14}
                            sx={{
                              color: (theme) =>
                                theme.palette.onPrimaryContainer.main,
                            }}
                          >
                            Enviaremos uma confirmação de agendamento para o seu
                            email
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CheckoutOrderCreatedPending;
