import { Avatar, Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useAppointment } from "../../../hooks/useAppointment";
import { useAuthenticatedUser } from "../../../store/user";
import { maskCpf, maskPhoneNumber } from "../../../utils/masks";
import { paymentMethodIcons } from "../PaymentMethod";

export const CheckoutSummary = () => {
  const { paymentCardForm, paymentMethod, account, dependent } =
    useAppointment();
  const { data: user } = useAuthenticatedUser();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={{ py: 3 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              fontSize={14}
              sx={{
                color: (theme) => theme.palette.onPrimaryContainer.main,
                fontWeight: 500,
              }}
            >
              Dados do paciente
            </Typography>
          </Grid>
          {account === 0 && user && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container>
                    <Grid item lg={4} md={4} xs={12}>
                      <Typography
                        fontSize={12}
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        Nome
                      </Typography>
                      <Typography
                        fontSize={16}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                        }}
                      >
                        {user.paciente.nomepaciente}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Typography
                        fontSize={12}
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        Email
                      </Typography>
                      <Typography
                        fontSize={16}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                        }}
                      >
                        {user.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          {account === 1 && dependent && user && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container>
                    <Grid item lg={4} md={4} xs={12}>
                      <Typography
                        fontSize={12}
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        Nome
                      </Typography>
                      <Typography
                        fontSize={16}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                        }}
                      >
                        {dependent.nomepaciente}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Typography
                        fontSize={12}
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        Email (responsável)
                      </Typography>
                      <Typography
                        fontSize={16}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                        }}
                      >
                        {user.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  fontSize={14}
                  sx={{
                    color: (theme) => theme.palette.onPrimaryContainer.main,
                    fontWeight: 500,
                  }}
                >
                  Pagamento
                </Typography>
              </Grid>
              {paymentMethod &&
                (paymentMethod.formapagamento === "credito" &&
                paymentCardForm ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container>
                          <Grid item lg={1} md={1} xs={1}>
                            <Avatar
                              sx={{
                                bgcolor: (theme) =>
                                  theme.palette.primaryContainer.main,
                              }}
                            >
                              <img
                                src={
                                  paymentMethodIcons.find(
                                    (item) =>
                                      item.name === paymentMethod.formapagamento
                                  )?.icon
                                }
                                alt="teste"
                                width={24}
                                height={24}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item lg={7} md={7} xs={11}>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                              }}
                            >
                              {paymentMethod.nome}
                            </Typography>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                              }}
                            >
                              {` **** **** **** ${paymentCardForm.cardNumber
                                .replace(" ", "")
                                .slice(
                                  paymentCardForm.cardNumber.length - 4,
                                  paymentCardForm.cardNumber.length
                                )}`}
                            </Typography>

                            <Typography
                              fontSize={12}
                              sx={{
                                color: (theme) => theme.palette.text.disabled,
                              }}
                            >
                              {paymentCardForm.cardOwnerName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container>
                          <Grid item lg={1} md={1} xs={1}>
                            {!matchesSM && <Avatar
                              sx={{
                                bgcolor: (theme) =>
                                  theme.palette.primaryContainer.main,
                              }}
                            >
                              <img
                                src={
                                  paymentMethodIcons.find(
                                    (item) =>
                                      item.name === paymentMethod.formapagamento
                                  )?.icon
                                }
                                alt="pagamento"
                                width={24}
                                height={24}
                              />
                            </Avatar>}
                          </Grid>
                          <Grid item lg={7} md={7} xs={11}>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                              }}
                            >
                              {paymentMethod.nome}
                            </Typography>
                            <Typography
                              fontSize={12}
                              sx={{
                                color: (theme) => theme.palette.text.disabled,
                              }}
                            >
                              A consulta só é confirmada quando o pagamento for
                              aprovado
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  fontSize={14}
                  sx={{
                    color: (theme) => theme.palette.onPrimaryContainer.main,
                    fontWeight: 500,
                  }}
                >
                  Dados complementares
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container>
                      <Grid item lg={4} md={4} xs={12}>
                        <Typography
                          fontSize={12}
                          sx={{
                            color: (theme) => theme.palette.text.disabled,
                          }}
                        >
                          {account === 0
                            ? `CPF (Documento)`
                            : `CPF (Documento do responsável)`}
                        </Typography>
                        {paymentCardForm &&
                        paymentMethod &&
                        paymentMethod.formapagamento !== "transferencia" ? (
                          <Typography
                            fontSize={16}
                            sx={{
                              color: (theme) =>
                                theme.palette.onPrimaryContainer.main,
                            }}
                          >
                            {maskCpf(paymentCardForm.userDocument)}
                          </Typography>
                        ) : (
                          account === 0 &&
                          user &&
                          user.paciente.cpf && (
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                              }}
                            >
                              {maskCpf(user.paciente.cpf)}
                            </Typography>
                          )
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} xs={12}>
                        <Typography
                          fontSize={12}
                          sx={{
                            color: (theme) => theme.palette.text.disabled,
                          }}
                        >
                          {account === 0
                            ? `Número de celular (Contato)`
                            : `Número de celular (Contato do responsável)`}
                        </Typography>

                        {account === 0 &&
                          user &&
                          user.paciente.telefonecelular && (
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                              }}
                            >
                              {maskPhoneNumber(user.paciente.telefonecelular)}
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
