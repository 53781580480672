import { QueryKey } from "react-query";

export const createUseAvailabilityMapKey = ({
  length = 30,
  serviceItemId,
  clinicId,
  date,
  healthPlanId,
  patientId,
  servicePackageId,
}: {
  date: string;
  clinicId: string;
  serviceItemId: string[];
  length: number;
  healthPlanId?: string;
  patientId?: string;
  servicePackageId?: string;
}): QueryKey => [
  "useAvailabilityMapKey",
  clinicId,
  length,
  serviceItemId,
  date,
  healthPlanId,
  patientId,
  servicePackageId,
];

export const createUseAvailabilityTimeKey = ({
  serviceItemId,
  clinicId,
  date,
  healthPlanId,
  patientId,
  servicePackageId
}: {
  date: string;
  clinicId: string;
  serviceItemId: string[];
  healthPlanId?: string;
  patientId?: string;
  servicePackageId?: string;
}): QueryKey => [
  "useAvailabilityTimeKey",
  clinicId,
  serviceItemId,
  date,
  healthPlanId,
  patientId,
  servicePackageId
];
