import {
  Alert,
  AlertTitle,
  AppBar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  IconButton,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Step,
  StepButton,
  Stepper,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import creditCardType from "credit-card-type";
import { X } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogoSection from "../../../components/LogoSection";
import { useAppointment } from "../../../hooks/useAppointment";
import { useAppointmentCheckout } from "../../../hooks/useAppointmentCheckout";
import { checkCreditCard } from "../../../services/creditCard";
import { fetchOrderStatus } from "../../../store/appointment";
import { useApplicationId, useAuthenticatedUser } from "../../../store/user";
import { IPayServiceOrderResponse } from "../../../types";
import { removeMaskCpf } from "../../../utils/masks";
import { formatExpirationDate, validarCPF } from "../../../utils/roles";
import CheckoutOrderCreatedApproved from "../OrderCreatedApproved";
import CheckoutOrderCreatedPending from "../OrderCreatedPending";
import CheckoutOrderCreatedProcessing from "../OrderCreatedProcessing";
import CheckoutPaymentMethod from "../PaymentMethod";
import { CheckoutSummary } from "../Summary";
import { CheckoutSidebar } from "./componentes/CheckoutSidebar";

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: "100%",
  height: "100%",
  flexGrow: 1,
}));

const steps = ["Pagamento", "Resumo"];

const CARD_NUMBER_MAX_LENGTH = 19;
const CARD_NUMBER_MIN_LENGTH = 12;
const CARD_NUMBER_MIN_LENGTH_TYPE = 4;
const EXPIRATION_DATE_MAX_LENGTH = 7;
const EXPIRATION_DATE_MIN_LENGTH = 5;
const SECURITY_CODE_MAX_LENGTH = 4;
const SECURITY_CODE_MIN_LENGTH = 3;
const USER_DOCUMENT_MAX_LENGTH = 11;

const CheckoutLayout = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const { data: user } = useAuthenticatedUser();

  const { isPayment, isCombo, handleChangeIsCombo, handleChangeIsPayment } =
    useAppointmentCheckout();
  const { data: applicationId } = useApplicationId();

  const {
    paymentCardForm,
    paymentMethod,
    handleChangePaymentCardForm,
    handleCreateServiceOrder,
    handleChangeAppointmentPaymentMethod,
    account,
    dependent,
    handleReset,
  } = useAppointment();

  const [cardNumber, setCardNumber] = useState<string>(
    paymentCardForm?.cardNumber || ""
  );
  const [cardOwnerName, setCardOwnerName] = useState<string>(
    paymentCardForm?.cardOwnerName || ""
  );
  const [expirationDate, setExpirationDate] = useState<string>(
    paymentCardForm?.expirationDate || ""
  );
  const [securityCode, setSecurityCode] = useState<string>(
    paymentCardForm?.securityCode || ""
  );
  const [userDocument, setUserDocument] = useState<string>(
    paymentCardForm?.userDocument || ""
  );
  const [installments, setInstallments] = useState("1");
  const [brand, setBrand] = useState(
    paymentCardForm?.cardNumber
      ? creditCardType(paymentCardForm.cardNumber)[0].type
      : paymentCardForm?.brand
      ? paymentCardForm?.brand
      : ""
  );

  const [cardNumberError, setCardNumberError] = useState<string>(" ");
  const [cardOwnerNameError, setCardOwnerNameError] = useState<string>(" ");
  const [expirationDateError, setExpirationDateError] = useState<string>(" ");
  const [securityCodeError, setSecurityCodeError] = useState<string>(" ");
  const [userDocumentError, setUserDocumentError] = useState<string>(" ");
  const [brandError, setBrandError] = useState(" ");
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState<string>("");

  const [activeStep, setActiveStep] = useState(0);
  const [paymentPix, setPaymentPix] = useState(false);
  const [approvedServiceOrder, setApprovedServiceOrder] = useState(false);
  const [processingServiceOrder, setProcessingServiceOrder] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [paymentOrder, setPaymentOrder] =
    useState<IPayServiceOrderResponse | null>(null);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [skipped, setSkipped] = useState(new Set<number>());
  const navigate = useNavigate();
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const validateStepOne = () => {
    if (cardNumberError !== " ") {
      return false;
    }
    if (cardOwnerNameError !== " ") {
      return false;
    }
    if (expirationDateError !== " ") {
      return false;
    }
    if (securityCodeError !== " ") {
      return false;
    }
    if (userDocumentError !== " ") {
      return false;
    }

    if (cardNumber === "") {
      setCardNumberError("O número do cartão é obrigatório");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }
    if (paymentMethod && paymentMethod.debito && brand === "") {
      setBrandError("A bandeira do cartão é obrigatório");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }
    if (cardOwnerName === "") {
      setCardOwnerNameError("O nome do titular do cartão é obrigatório");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }
    if (expirationDate === "") {
      setExpirationDateError("A data de validade do cartão é obrigatório");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }
    if (securityCode === "") {
      setSecurityCodeError("O CVV é obrigatório");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }
    if (userDocument === "") {
      setUserDocumentError("O CPF do titular do cartão é obrigatório");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return false;
    }

    return true;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (
      activeStep === 0 &&
      paymentMethod &&
      paymentMethod.formapagamento === "cartao" &&
      validateStepOne()
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      handleChangePaymentCardForm({
        cardNumber,
        cardOwnerName,
        documentType: "cpf",
        expirationDate,
        phoneNumber: user ? user.paciente.telefonecelular : "",
        securityCode,
        userDocument,
        installment: installments,
        brand,
        debit: paymentMethod.debito,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (
      activeStep === 0 &&
      paymentMethod &&
      paymentMethod.formapagamento === "pix"
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    if (activeStep === 1 && applicationId) {
      onFinish();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onChangeInstallment = (event: SelectChangeEvent) => {
    setInstallments(event.target.value as string);
  };

  const onChangeBrand = (event: SelectChangeEvent) => {
    setBrandError(" ");
    setBrand(event.target.value as string);
  };

  const onChangeCardNumber = (value: string) => {
    if (value.length > CARD_NUMBER_MAX_LENGTH) {
      return;
    }
    value = value.replace(" ", "");
    const regex = /^([0-9 ]+)+$/;
    setCardNumber(value);
    setBrand("");
    setCardNumberError(" ");

    if (!value) {
      return setCardNumberError("O número do cartão é obrigatório");
    }

    if (!regex.test(value)) {
      return setCardNumberError("Revise esse dado");
    }

    if (value.length < CARD_NUMBER_MIN_LENGTH) {
      return setCardNumberError(
        "A quantidade de caracteres digitados é inválida"
      );
    }

    if (value.length >= CARD_NUMBER_MIN_LENGTH_TYPE) {
      const creditCard = creditCardType(value);
      if (creditCard.length > 0) {
        setBrand(creditCard[0].type);
      } else {
        setBrand("");
      }
    } else {
    }

    const checked = checkCreditCard(value);
    if (!checked.success) {
      return setCardNumberError(
        checked.message || "Número do cartão é inválido"
      );
    }
  };

  const onChangeCardOwnerName = (value: string) => {
    const regex = /^[a-zA-Z-À-Ÿà-ÿ][A-Za-zÀ-Ÿà-ÿ ,.']+$/;
    setCardOwnerName(value);
    setCardOwnerNameError(" ");
    if (!value) {
      return setCardOwnerNameError("O nome do titular do cartão é obrigatório");
    }

    if (!regex.test(value)) {
      return setCardOwnerNameError("Revise esse dado");
    }
  };

  const onChangeExpirationDate = (value: string) => {
    value = formatExpirationDate(value);
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    // const regex = /^(0[1-9]|1[0-2])( )?\/( )?([2][0]\d{2}|\d{2})$/;
    setExpirationDate(value);
    setExpirationDateError(" ");

    if (!value) {
      return setExpirationDateError("A data de validade é obrigatório");
    }

    if (!regex.test(value)) {
      return setExpirationDateError("Insira uma data válida");
    }

    if (
      value.length > EXPIRATION_DATE_MAX_LENGTH ||
      value.length < EXPIRATION_DATE_MIN_LENGTH
    ) {
      return setExpirationDateError(
        "A quantidade de caracteres digitados é inválida"
      );
    }

    const date_array = value.split("/");
    const month = Number(date_array[0]) - 1;
    const year = Number(date_array[1]) + 2000; // Adding 2000 to get the 4-digit year

    if (month < 0 || month > 11) {
      return setExpirationDateError("Insira uma data válida");
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return setExpirationDateError("Insira uma data válida");
    }
  };

  const onChangeSecurityCode = (value: string) => {
    const regex = /^[0-9]+$/;
    setSecurityCode(value);
    setSecurityCodeError(" ");
    if (!value) {
      return setSecurityCodeError("O CVV é obrigatório");
    }

    if (!regex.test(value)) {
      return setSecurityCodeError("Revise este dado");
    }

    if (value.length > SECURITY_CODE_MAX_LENGTH) {
      return setSecurityCodeError(
        "A quantidade de caracteres digitados é inválida"
      );
    }

    if (value.length < SECURITY_CODE_MIN_LENGTH) {
      return setSecurityCodeError(
        "A quantidade de caracteres digitados é inválida"
      );
    }
  };

  const onChangeUserDocument = (value: string) => {
    value = removeMaskCpf(value);
    const regex = /^([0-9.-]+)+$/;
    if (value.length > USER_DOCUMENT_MAX_LENGTH) {
      return;
    }
    setUserDocument(value);
    setUserDocumentError(" ");

    if (!value) {
      return setUserDocumentError("O CPF é obrigatório");
    }

    if (!validarCPF(value)) {
      return setUserDocumentError("O CPF é inválido");
    }

    if (!regex.test(value)) {
      return setUserDocumentError("Revise este dado");
    }
  };

  const onFinish = async () => {
    try {
      setIsPaying(true);
      const patientId =
        account === 0 && user
          ? user.paciente.idpaciente
          : dependent
          ? dependent.idpaciente
          : "";
      const response = await handleCreateServiceOrder({
        applicationId: applicationId ? applicationId.idusuario : "",
        email: user
          ? user.email !== ""
            ? user.email
            : user.paciente.email
          : "",
        patientId,
        serviceChannelId:
          account === 0 && user
            ? user.paciente.idcanalatendimento
            : dependent
            ? dependent?.idcanalatendimento
            : "",
        userId: account === 1 && user ? user.paciente.idpaciente : "",
        isCombo,
      });
      setIsPaying(false);
      if (response.status === "pendente") {
        handleReset();
        setPaymentPix(true);
        setPaymentOrder(response);
      }
      if (response.status === "aprovada") {
        if (!isCombo) {
          handleReset();
          handleChangeIsPayment(false);
          return navigate(`${response.appointment_id}/summary`, {
            replace: true,
          });
        }
        setPaymentOrder(response);
        setApprovedServiceOrder(true);
      }
      if (response.status === "processando") {
        handleReset();
        setProcessingServiceOrder(true);
        setPaymentOrder(response);
      }
      if (response.status === "error") {
        if (response.error_message) {
          return setCheckoutErrorMessage(response.error_message);
        }
        return setCheckoutErrorMessage(
          "Não foi possível finalizar o seu pagamento. Tente novamente mais tarde!"
        );
      }
    } catch (error: any) {
      console.log("ERROR :::", error);
      setIsPaying(false);
      if (error.response) {
        setCheckoutErrorMessage(error.response.data.error.message);
      } else {
        setCheckoutErrorMessage(
          "Não foi possível finalizar a sua inscrição. Verifique os dados e tente novamente!"
        );
      }
    }
  };

  useEffect(() => {
    if (paymentOrder && paymentOrder.order_id) {
      const intervalId = setInterval(() => {
        fetchOrderStatus({
          serviceOrderId: paymentOrder.service_order_id,
        }).then((data) => {
          if (data.status === "quitado") {
            if (!isCombo) {
              handleReset();
              navigate(`${paymentOrder.appointment_id}/summary`, {
                replace: true,
              });
            } else {
              setPaymentOrder(paymentOrder);
              setApprovedServiceOrder(true);
            }
          }
          if (data.status === "cancelado") {
            handleReset();
            toast.error(
              "O seu agendamento foi cancelado pois ocorreu um erro com o seu pagamento"
            );
            navigate(`/agendamento`, { replace: true });
          }
        });
      }, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [paymentPix, paymentOrder, isCombo, navigate, handleReset]);

  return (
    <>
      <Dialog
        open={isPayment}
        fullScreen
        onClose={() => {
          handleChangeIsPayment(false);
          handleChangeIsCombo(false);
        }}
      >
        <AppBar
          color="inherit"
          elevation={0}
          position="relative"
          sx={{
            bgcolor: theme.palette.primary.main,
          }}
        >
          <Container maxWidth="xl">
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LogoSection />
              <IconButton
                aria-label="close"
                onClick={() => {
                  handleChangeAppointmentPaymentMethod(null);
                  handleChangePaymentCardForm(null)
                  handleChangeIsPayment(false);
                  handleChangeIsCombo(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  color: (theme) => theme.palette.onPrimary.main,
                }}
              >
                <X />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>

        <Main theme={theme}>
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
            }}
          >
            {approvedServiceOrder && paymentOrder && (
              <CheckoutOrderCreatedApproved order={paymentOrder} />
            )}

            {processingServiceOrder && paymentOrder && (
              <CheckoutOrderCreatedProcessing order={paymentOrder} />
            )}

            {paymentPix && paymentOrder && (
              <CheckoutOrderCreatedPending order={paymentOrder} />
            )}

            {!approvedServiceOrder && !paymentPix && !paymentOrder && (
              <>
              <Grid container mt={2} spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};

                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step key={label} {...stepProps}>
                          <StepButton
                            color="inherit"
                            onClick={() => handleStep(index)}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      );
                    })}
                  </Stepper>

                  {matchesSM && <CheckoutSidebar />}

                  {activeStep === 0 && (
                    <CheckoutPaymentMethod
                      cardNumber={cardNumber}
                      cardOwnerName={cardOwnerName}
                      expirationDate={expirationDate}
                      installments={installments}
                      securityCode={securityCode}
                      userDocument={userDocument}
                      onChangeCardNumber={onChangeCardNumber}
                      onChangeCardOwnerName={onChangeCardOwnerName}
                      onChangeExpirationDate={onChangeExpirationDate}
                      onChangeSecurityCode={onChangeSecurityCode}
                      onChangeUserDocument={onChangeUserDocument}
                      onChangeInstallment={onChangeInstallment}
                      cardNumberError={cardNumberError}
                      cardOwnerNameError={cardOwnerNameError}
                      expirationDateError={expirationDateError}
                      securityCodeError={securityCodeError}
                      userDocumentError={userDocumentError}
                      brand={brand}
                      brandError={brandError}
                      onChangeBrand={onChangeBrand}
                    />
                  )}
                  {activeStep === 1 && <CheckoutSummary />}
                  <Grid container mb={2}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="end"
                      position="relative"
                      display="flex"
                    >
                      <Stack spacing={3} direction="row">
                        {activeStep > 0 && (
                          <Button variant="outlined" onClick={handleBack}>
                            Voltar
                          </Button>
                        )}
                        {isPaying ? (
                          <Button variant="contained" disabled>
                            <CircularProgress color="primary" />
                          </Button>
                        ) : (
                          <Button variant="contained" onClick={handleComplete}>
                            {activeStep === steps.length - 1
                              ? "Finalizar"
                              : "Continuar"}
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!matchesSM && <CheckoutSidebar />}
              </>
            )}
          </Container>
        </Main>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={checkoutErrorMessage !== ""}
          onClose={() => setCheckoutErrorMessage("")}
          autoHideDuration={5000}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            <AlertTitle>Erro - Agendamento cancelado</AlertTitle>
            {checkoutErrorMessage}
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
};

export default CheckoutLayout;
